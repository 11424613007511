:root {
  --off-white: #f2f2f2;
  --ribbit-green: rgba(19, 142, 0);
  --header-background: #f2f2f2;
  --header-btn-hover-color: #e2e2e2;
  --footer-background: rgb(121, 121, 121);
  --footer-text: rgba(255, 255, 255, 0.5);
  --footer-fs: 0.75rem;
  --form-field-fs: 0.5rem;
  --form-field-title-fs: 0.75rem;
  --form-label-fs: 1rem;
  --form-field-color: #3f3f3f;
  --form-field-title-color: #3f3f3f;
  --default-container-height: 750px;

  --gray-900: #1a202c;
  --gray-800: #2d3748;
  --gray-700: #4a5568;
  --gray-600: #718096;
  --gray-500: #a0aec0;
  --gray-400: #cbd5e0;
  --gray-300: #e2e8f0;
  --gray-200: #edf2f7;
  --gray-100: #f7fafc;
  --red-100: #fff5f5;
  --red-200: #fed7d7;
  --red-300: #feb2b2;
  --red-400: #fc8181;
  --red-500: #f56565;
  --red-600: #e53e3e;
  --red-700: #c53030;
  --red-800: #9b2c2c;
  --red-900: #742a2a;
  --blue-100: #ebf8ff;
  --blue-200: #bee3f8;
  --blue-300: #90cdf4;
  --blue-400: #63b3ed;
  --blue-500: #4299e1;
  --blue-600: #3182ce;
  --blue-700: #2b6cb0;
  --blue-800: #2c5282;
  --blue-900: #2a4365;
  --white: white;
  --red: #f56565;
  --text-decoration-color: var(--gray-400);
  --text-color: var(--gray-800);
  --focus-ring-color: var(--blue-500);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibilit;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 1rem;
  line-height: 1.6;
  text-align: center;
  cursor: default;
  text-decoration-skip-ink: 'auto';

  /* min-width: 585px; */

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  /* font-family: -apple-system, BlinkMacSystemFont, 'avenir next', avenir,
    'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial,
    sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@media (prefers-color-scheme: dark) {
  body {
    color: white;
    background-color: var(--gray-900);
    caret-color: white;
  }
}
/* ======================================================================================== */
/* -------------------------------- T Y P E O G R A P H Y --------------------------------- */
/* ======================================================================================== */
.font-xs {
  font-size: 0.5rem;
}
.font-sm {
  font-size: 0.75rem;
}
.font-lg {
  font-size: 1.5rem;
}
.font-xl {
  font-size: 1.75rem;
}
.font-xxl {
  font-size: 2.5rem;
}
h1,
h2,
strong,
.footer-text-bold,
.font-bold {
  font-weight: 700;
}
.align-text-center {
  text-align: center;
}

.footer-text-bold,
.footer-text {
  color: var(--footer-text);
  font-size: var(--footer-fs);
}
/* ======================================================================================== */
/* ----------------------------------  B U T T O N S -------------------------------------- */
/* ======================================================================================== */
a {
  outline-color: gray;
}
a:active,
a:focus {
  outline-width: 1px;
  outline-style: unset;
}

.btn-signup {
  display: inline-block;
  padding: 0;
  margin-right: 1.25em;
  font-size: 0.85rem;
  font-weight: 600;
  color: var(--ribbit-green);
  text-transform: uppercase;
  background-color: white;
  outline: none;
  border: none;
  cursor: pointer;
}
.btn-signup:hover {
  border-bottom: 1px solid darkgreen;
}
.btn-signup:focus {
  border: none;
}
.btn-login {
  display: inline-block;
  font-size: 0.85rem;
  font-weight: 600;
  color: var(--ribbit-green);
  background-color: transparent;
  padding: 0.35em 1em 0.35em 1em;
  border: 2px solid var(--ribbit-green);
  border-radius: 0.5em;
  text-transform: uppercase;
  cursor: pointer;
}
.btn-login:hover {
  background-color: var(--header-btn-hover-color);
  /* #eee; */
}
.btn-login:focus {
  background-color: #eee;
  outline-style: none;
}
.btn-login-grp {
  padding: 0.5em 0 0 0;
  margin: 0 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.btn-styled-pill {
  display: inline-block;
  margin-top: 0em;
  padding: 0.35em 2.5em;
  border: 3px solid darkgreen;
  border-radius: 50px;
  font-size: 1.5rem;
  font-weight: 600;
  color: darkgreen;
  cursor: pointer;
  outline-style: none;
  background-color: inherit;
}
.btn-styled-pill:focus {
  background-color: #eee;
  outline-style: none;
}
.btn-styled-pill:hover {
  background-color: #eee;
}

/* Button for the Add Property first page */
.btn-add-prop {
  color: white;
  background-color: dodgerblue;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 0.3em 0.75em;
  border: none;
  margin-bottom: 1em;
  border-radius: 5px;
}
.btn-add-prop:hover {
  background-color: blueviolet;
}
.btn-add-prop:disabled {
  background-color: gray;
  color: lightgrey;
  outline: none;
}
.btn-map-done,
.btn-addr-to-map {
  display: inline-block;
  color: white;
  background-color: dodgerblue;
  font-size: 2rem;
  font-weight: 600;
  padding: 0.5em 1em;
  border: none;
  margin-bottom: 1em;
  border-radius: 10px;
  margin: 0 0.5em;
  max-width: 545px;
}
.btn-addr-to-map:active,
.btn-addr-to-map:hover {
  background-color: blueviolet;
  outline: none;
}
.btn-addr-to-map:disabled {
  background-color: gray;
  color: lightgrey;
  outline: none;
}
.btn-map-done {
  margin-top: 0.25em;
  max-width: 300px;
}

/* ======================================================================================== */
/* ------------------------------------ L A Y O U T --------------------------------------- */
/* ======================================================================================== */

.app {
  display: flex;
  flex-direction: column;
  width: auto;
  height: fit-content;
  overflow-x: hidden;
  /* border: 2px solid red; */
}

main {
  margin-top: -6px;
  height: 100%;
  width: auto;
  /* border: 1px solid orange; */
}

/* .app-container {
  width: 100%;
  height: auto;
  border: 5px solid fuchsia;
  flex-direction: column;
} */

.row {
  display: flex;
  width: 100%;
  height: auto;
  /* border: 4px solid orange; */
}

.text-row-accent {
  border-bottom: 2px solid darkgreen;
  background: green;
  color: white;
  letter-spacing: 1.4px;
}

.space-center {
  justify-content: center;
}
.space-evenly {
  justify-content: space-evenly;
}
.space-around {
  justify-content: space-around;
}
.space-between {
  justify-content: space-between;
}
.flex-dir-col {
  flex-direction: column;
}
.flex-dir-row {
  flex-direction: row;
}

/* ======================================================================================== */
/* ------------------------------------- C O L U M N S ------------------------------------ */
/* ======================================================================================== */
.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  /* border: 2px solid firebrick; */
}
.col-10 {
  width: 10%;
}
.col-20 {
  width: 20%;
}
.col-25 {
  width: 25%;
}
.col-50 {
  width: 50%;
}
.col-60 {
  width: 60%;
}
.col-66 {
  width: 66%;
}
.col-70 {
  width: 70%;
}
.col-75 {
  width: 75%;
}
.col-80 {
  width: 80%;
}
.col-90 {
  width: 90%;
}
.col-full {
  width: 100%;
}
.col-height-full {
  height: 500px;
}
.height-750 {
  height: 750px;
}
.content-flex-center {
  justify-content: center;
  align-items: center;
}
.content-flex-left {
  justify-content: flex-start;
  align-items: center;
}

.vh-100 {
  height: 100vh;
}
/* ======================================================================================== */
/* ---------------------------------------------------------------------------------------- */
/* ======================================================================================== */
.loading {
  font-weight: 700;
  font-size: 1.5rem;
}

.header {
  /* height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid darkgreen;
  background-color: var(--off-white); */

  /* Fix the navbar to the top of the page */
  /* position: fixed;
  top: 0;
  width: 100%;
  z-index: 999; */
}

/* Logo and Navbar together - limit to 50% of the entire header width */
.logo-nav {
  display: flex;
  align-items: baseline;
  width: 50%;
  height: auto;
}

/* Header .logo {
  margin: 0 0.5em 0 0.5em;
  padding: 0;
  font-weight: 700;
  font-size: 2.25em;
  letter-spacing: -0.5px;
  color: darkgreen;
} */

/* .navbar {
  margin: 0.5em 0.5em 0em 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.navbar .navitem {
  font-size: 0.85rem;
  color: green;
  font-weight: 600;
  padding: 0 0.3em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
}
.navbar .navitem:visited {
  color: darkgreen;
}
.navbar .navitem:hover {
  border-bottom: 1px solid darkgreen;
}
.navbar .navitem:first-child {
  margin-left: -1.25em;
}
.navbar .navitem:last-child {
  margin-right: 1.5em;
} */

/* ======================================================================================== */
/* ----------------------------------- F O O T E R ---------------------------------------- */
/* ======================================================================================== */
/* .footer {
  background-color: var(--footer-background);
  height: 300px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.footer h3 {
  font-weight: 700;
  font-size: 1.2rem;
  color: var(--off-white);
  letter-spacing: 2px;
}
.footer .copyright-text {
  font-size: 0.75rem;
  color: var(--footer-text);
  margin-bottom: 15px;
} */

.home-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: auto;
  /* height: 95vh; */
  /* height: var(--default-container-height); */
  height: 100vh;
  background-color: white;
  /* background-color: var(--ribbit-green); */
  background-image: url(./assets/unsplash-L2oedF1AsH8.jpg); 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  /* border: 5px purple solid; */
}
.home-page h1 {
  color: var(--ribbit-green);
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 3px;
  /* border: 1px solid red; */
}
/* ======================================================================================== */
/* ---------------------------------  C O M P O N E N T S  -------------------------------- */
/* ======================================================================================== */
.address_details {
  margin: 0.35em 0 0.75em 1em;
  border: 1px solid darkgreen;
  border-radius: 8px;
  padding: 10px;
  max-width: fit-content;
}

.addr-l-1 {
  grid-area: addr-l-1;
}
.addr-1 {
  grid-area: addr-1;
}
.addr-l-2 {
  grid-area: addr-l-2;
}
.addr-2 {
  grid-area: addr-2;
}
.addr-l-city {
  grid-area: addr-l-city;
}
.addr-city {
  grid-area: addr-city;
}
.addr-l-muni {
  grid-area: addr-l-muni;
}
.addr-muni {
  grid-area: addr-muni;
}

.addr-l-state {
  grid-area: addr-l-state;
}
.addr-state {
  grid-area: addr-state;
}

.addr-l-region {
  grid-area: addr-l-reg;
}
.addr-region {
  grid-area: addr-reg;
}
.addr-l-post {
  grid-area: addr-l-post;
}
.addr-post {
  grid-area: addr-post;
}
.addr-l-country {
  grid-area: addr-l-coun;
}
.addr-country {
  grid-area: addr-coun;
}

.add-prop__addrform {
  /* border: 1px solid fuchsia; */
  width: auto;
  display: grid;
  grid-template-columns: minmax(auto, 1fr) 10px repeat(3, 100px);
  grid-template-rows: repeat(8, 40px);
  grid-template-areas:
    'addr-l-1     .  addr-1     addr-1     addr-1'
    'addr-l-2     .  addr-2     addr-2     addr-2'
    'addr-l-city  .  addr-city  addr-city  ......'
    'addr-l-muni  .  addr-muni  addr-muni  ......'
    'addr-l-state .  addr-state addr-state ......'
    'addr-l-reg   .  addr-reg   addr-reg   ......'
    'addr-l-post  .  addr-post  ......     ......'
    'addr-l-coun  .  addr-coun  addr-coun  ......';
}
.form-label {
  font-size: 0.75rem;
  font-weight: 600;
  text-align: right;
  transform: translateY(25%);
  /* margin: 5px 10px; */
}
.add-prop__addrform select:not(focus),
.add-prop__addrform input[type='text']:not(focus),
.add-prop__addrform input[type='text'] {
  margin: 5px 0;
  border: 1px solid green;
  border-radius: 8px;
  padding-left: 0.5em;
  outline: none;
}
.add-prop__addrform select:hover,
.add-prop__addrform select:focus,
.add-prop__addrform input[type='text']:hover,
.add-prop__addrform input[type='text']:focus {
  border: 2px solid dodgerblue;
  border-radius: 5px;
  outline: none;
}

.addr-map-box {
  height: fit-content;
  min-width: fit-content;
  margin: 0; /* 10px; */
  margin-top: 0; /* 5px; */
}
.addr-to-map-btn {
  color: white;
  background-color: dodgerblue;
  font-size: 1.5rem;
  padding: 1rem 2rem;
}
.new-prop__r-1-c-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 2px solid orange; */
}

/* ======================================================================================== */
/* -----------------------------------  M A P  P A G E S ---------------------------------- */
/* ======================================================================================== */
.map-workspace-container {
  padding-left: 2px;
  padding-right: 2px;
  height: auto;
  /* border: 2px solid fuchsia; */
}

.map-workspace {
  display: flex;
  justify-content: space-between;
  height: 650px;
  min-width: 650px;
  /* border: 5px solid fuchsia; */
  overflow-x: visible;
}

.map-display {
  flex-shrink: 0;
  min-width: 550px;
  border: 2px dashed red;
}

.sidebar-left {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-shrink: 1;
  /* width: 25%; */
  min-width: 150px;
  /* text-align: center; */
  border: 2px solid green;
}

.sidebar-right {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-shrink: 1;
  min-width: 150px;
  border: 2px solid blue;
}

.map-status-bar {
  margin-top: 0.5em;
  margin-bottom: 0;
  width: auto;
  height: 3em;
  display: flex;
  justify-content: center;
  flex-direction: center;
  align-items: center;
  /* border: 2px solid orange; */
}

.map-status-bar p {
  padding: 0.5em;
  margin: 0.5em;
  text-align: center;
  font-weight: 700;
}

.map-btn {
  padding: 2px 4px;
  margin: 2px;
}
/* -----------------  DEV TEST ---------------------------------------- */

.dev-test {
  height: 1500px;
  /* margin-top: 55px; */
  /* height: fit-content; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  background-color: #b1ffa5;
  color: var(--ribbit-green);
}
.dev-test h1 {
  font-weight: 700;
  font-size: 1.1rem;
  letter-spacing: 2px;
}
.dev-test h1.title {
  font-size: 2rem;
}
/* -------------------------------- A D D   P R O P E R T Y   P A G E ------------------- */
.prop-type-option {
  display: inline-block;
  min-width: auto;
}
.new-prop__row {
  justify-content: space-between;
  border-top: 3px solid darkgreen;
}

.add-property-1 {
  /* border: 1px solid fuchsia; */
  /* margin-top: 57px; */
  height: 100%;
}

.add-prop__dialog--title {
  font-weight: 600;
  margin-top: 0.5em;
}

.add-prop__q1--title {
  font-weight: 600;
  margin-top: 0.75em;
}

.add-prop__q2--title {
  font-weight: 600;
  margin-top: 0.85em;
}

.ap-f-q1 {
  margin-top: 0.25em;
}
.ap-f-q1 label {
  margin: 0.1em 0.4em;
  color: darkgreen;
}

.ap-f-q2 {
  margin-top: 0.25em;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: auto;
  line-height: 2;
  /* border: 1px solid fuchsia; */
}
.ap-f-q2 label {
  margin: 0.1em 0.4em;
  color: blue;
}
.contact-details {
  font-size: 1rem;
  margin-top: 1em;
}
.contact-details label {
  font-weight: bold;
  font-size: 1rem;
}
input[id='contact-details'] {
  font-size: 1.2rem;
  width: 330px;
  margin-top: 0.5em;
  padding: 0.3em 0.3em;
  color: var(--form-field-color);
}
input[id='contact-details']:focus {
  outline: none;
  border: 1px solid green;
}

.ap-reg-link {
  margin: 0.5em auto;
  margin-bottom: 0.75em;
}
.add-prop__login--link:hover {
  color: green;
  transform: scale(2.5);
}

input[type='radio'] {
  color: orange;
}

.add-prop-2,
.add-prop-1 {
  /* border: 1px solid red; */
}

@media (max-width: 640px) {
  .row {
    flex-direction: column;
    align-content: center;
    min-width: 590px;
  }
  .new-prop-addr {
    align-items: center;
  }
}

/* ---------------------- A D D   P R O P E R T Y - 2   P A G E ------------------- */
.new-prop-addr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 0 12px 10px;
  /* border: 2px solid fuchsia; */
}

.addr-printable {
  width: 425px;
  height: auto;
  /* border: 1px solid red; */
}

.addr-text-area {
  resize: none;
  width: 94%;
  height: 110px;
  margin: 15px;
  font-size: 1.1rem;
  letter-spacing: 1px;
  line-height: 1.3;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.new-prop__addr--directions {
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
  align-self: flex-end;
  font-weight: 600;
  letter-spacing: 0.1em;
}

.new-prop__title--h2 {
  display: flex;
  font-size: 1.25rem;
  /* border: 1px solid green; */
  margin: 0.1em 0 0.15em 0;
}

.new-prop__page2--btn2 {
}

/* -------------------------------- N O T   F O U N D   P A G E ------------------- */
.not-found {
  /* height: 750px; */
  height: 100vh;
  justify-content: center;
  border: 1px solid green;
  /* margin-top: 57px; */
}
.not-found h1 {
  font-size: 2rem;
  font-weight: 700;
  color: darkgreen;
}

/* -----------------------------------  M A P B O X  ------------------------------- */
.mapbox {
}

.mapbox_marker {
  /* background-image: url('./assets/ribbit-pin.png'); */
  background-image: url('./assets/blue-pin.png');
  background-size: cover;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  cursor: pointer;
}

.mapbox-marker-com {
  background-image: url('./assets/mapbox-marker-icon-20px-red.png');
  background-size: cover;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}
.mapbox-marker-res {
  background-image: url('./assets/mapbox-marker-icon-20px-blue.png');
  background-size: cover;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}
.mapbox-marker-land {
  background-image: url('./assets/mapbox-marker-icon-20px-green.png');
  background-size: cover;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  cursor: pointer;
}
.mapbox-marker-mix {
  background-image: url('./assets/mapbox-marker-icon-20px-orange.png');
  background-size: cover;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  cursor: pointer;
}
.mapbox-marker-vac {
  background-image: url('./assets/mapbox-marker-icon-20px-purple.png');
  background-size: cover;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  cursor: pointer;
}




.mapboxgl-popup {
  max-width: 200px;
}

.com-prop-data-page {
  height: 100%;
  border: 3px solid blue;
}
