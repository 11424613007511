.address_details {
  margin: 0.35em 0 0.75em 1em;
  border: 1px solid darkgreen;
  border-radius: 8px;
  padding: 10px;
  max-width: fit-content;
}

.form-text-input { } 

.addr-l-streetNum {
  grid-area: addr-l-streetNum;
}
.addr-streetNum {
  grid-area: addr-streetNum;
}
.addr-l-street1 {
  grid-area: addr-l-street1;
}
.addr-street1 {
  grid-area: addr-street1;
}
.addr-l-street2 {
  grid-area: addr-l-street2;
}
.addr-street2 {
  grid-area: addr-street2;
}
.addr-l-city {
  grid-area: addr-l-city;
}
.addr-city {
  grid-area: addr-city;
}
.addr-l-muni {
  grid-area: addr-l-muni;
}
.addr-muni {
  grid-area: addr-muni;
}
.addr-l-state {
  grid-area: addr-l-state;
}
.addr-state {
  grid-area: addr-state;
}
.addr-l-region {
  grid-area: addr-l-reg;
}
.addr-region {
  grid-area: addr-reg;
}
.addr-l-post {
  grid-area: addr-l-post;
}
.addr-post {
  grid-area: addr-post;
}
.addr-l-country {
  grid-area: addr-l-coun;
}
.addr-country {
  grid-area: addr-coun;
}

.add-prop__addrform {
  /* border: 1px solid fuchsia; */
  width: auto;
  display: grid;
  grid-template-columns: minmax(auto, 1fr) 10px repeat(3, 100px);
  grid-template-rows: repeat(9, 40px);
  grid-template-areas:
    'addr-l-coun      .  addr-coun       addr-coun       ......'
    'addr-l-streetNum .  addr-streetNum  ......          ......'
    'addr-l-street1   .  addr-street1    addr-street1    addr-street1'
    'addr-l-street2   .  addr-street2    addr-street2    addr-street2'
    'addr-l-city      .  addr-city       addr-city       ......'
    'addr-l-muni      .  addr-muni       addr-muni       ......'
    'addr-l-state     .  addr-state      addr-state      ......'
    'addr-l-reg       .  addr-reg        addr-reg        ......'
    'addr-l-post      .  addr-post       ......          ......';
}

.form-label {
  font-size: 0.75rem;
  font-weight: 600;
  text-align: right;
  transform: translateY(25%);
  /* margin: 5px 10px; */
}
.add-prop__addrform select:not(focus),
.add-prop__addrform input[type='text']:not(focus),
.add-prop__addrform input[type='text'] {
  margin: 5px 0;
  border: 1px solid green;
  border-radius: 8px;
  padding-left: 0.5em;
  outline: none;
}
.add-prop__addrform select:hover,
.add-prop__addrform select:focus,
.add-prop__addrform input[type='text']:hover,
.add-prop__addrform input[type='text']:focus {
  border: 2px solid dodgerblue;
  border-radius: 5px;
  outline: none;
}

.addr-map-box {
  height: fit-content;
  min-width: fit-content;
  margin: 0; /* 10px; */
  margin-top: 0; /* 5px; */
}
.addr-to-map-btn {
  color: white;
  background-color: dodgerblue;
  font-size: 1.5rem;
  padding: 1rem 2rem;
}
.new-prop__r-1-c-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 2px solid orange; */
}




