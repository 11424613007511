
/* SIDENAV CSS */
.menuStrip {
  position: absolute;
  height: 100vh;
  width: 10px;
  left: 0;
  top: 74px;
  background-color: #0080ff;  /* #111; */
  opacity: 90%;
  cursor: pointer;
}

/* The side navigation menu */
.sidenav {
  position: absolute ; /* Change to 'fixed' to stay in place */
  height: 100vh; /* 100% Full-height */
  /* margin-top:73px; */
  width: 0; /* 0 width - change this with JavaScript */
  display: relative;
  z-index: 1; /* Stay on top */
  top: 74px; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black*/
  opacity :60%;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: width 0.25s ease-out; /* 0.5 second transition effect to slide in the sidenav */
  /* border: 2px solid fuchsia; */
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0px;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  color: #818181;
  cursor: pointer;
}
.sidenav .closebtn:hover {
  color: white;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s;
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}