.popupImg {
  height: 82px;
  width: 82px;
  object-fit: contain;
  margin: 0;
}

.popup-link {
  color: darkgreen;
  font-weight: 700;
  text-decoration: none;
}
.popup-link:visited {
  color: green;
}
.popup-link:hover {
  color: black;
}
